<template>
  <div>
    <Input v-model="poleInfo.name" readonly @click.native="openSelectPoleModal" class="pole-select-input">
      <template #append>
        <Button icon="ios-search" @click="openSelectPoleModal"></Button>
      </template>
    </Input>
    <ModalSelectPole v-model="showSelectModal" @selected="poleSelected" />
  </div>
</template>
<script>
import ModalSelectPole from './ModalSelectPole'
export default {
  name: 'PoleSelect',
  components: {
    ModalSelectPole,
  },
  model: {
    prop: 'value',
    event: 'poleChanged'
  },
  data(){
    return {
      poleInfo: this.value,
      showSelectModal: false,
    }
  },
  props: {
    value: {
      type: Object,
      default() { return {id: '', name: ''}; }
    },
  },
  created: function () {
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.poleInfo = newVal;
      // console.log('灯杆选择控件 绑定值变化1', this.poleInfo)
    },
    poleInfo(val) {
      this.$emit('poleChanged', val);
      // console.log('灯杆选择控件 绑定值变化2', this.poleInfo)
    },
  },
  mounted: function(){
    // console.log('灯杆选择控件 mounted', this.poleInfo)
  },
  destroyed: function(){
    // console.log('灯杆选择控件 destroyed')
  },
  methods: {
    openSelectPoleModal: function(){
      this.showSelectModal = true;

    },
    poleSelected: function(params){
      // console.log('新灯杆已选择', params);
      this.poleInfo.id = params.id;
      this.poleInfo.name = params.name;
    },
  },
}
</script>
<style scoped>
.pole-select-input{
}
.pole-select-input input{
  cursor: pointer !important;
}
</style>