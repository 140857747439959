<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">选择灯杆</div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button type="primary" :loading="loading" @click="ok">确认选择</Button>
    </div>
    <div class="select-area">
      <div class="tree-area">
        <Tree ref="tree" :data="groupTreeData" @on-select-change="treeSelected"></Tree>
      </div>
      <div class="list-area">
        <div class="list-other">
          <span class="ml10">{{showLang('com.tab.title')}}：</span>
          <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.device.pole.name')"
            style="width: 200px; margin-right: 10px;"></Input>
          <!-- {{showLang('com.import.cn.pole.type')}}：
          <Input type="text" v-model="filter.poleType" clearable style="width: 200px; margin-right: 10px;"></Input> -->
          杆臂数量：
          <Select :placeholder="showLang('save.select')" v-model="filter.armCount" style="width: 150px; margin-right: 10px;">
            <Option :value="0">全部</Option>
            <template v-for="n in 20">
              <Option :value="n" :key="n">{{n}}</Option>
            </template>
          </Select>
          <Button size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        </div>
        <div class="list-data">
          <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
            ref="chkTable" :radio-config="{highlight: true}" :data="list" height="auto"
            :row-config="{isHover: true}" row-id="id">
            <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
            <vxe-column width="50" type="radio" field="radio" fixed="left"></vxe-column>
            <vxe-column field="name" :title="showLang('com.tab.title')" width="200" header-align="center" fixed="left"></vxe-column>
            <vxe-column field="groupName" title="所在分区" sortable width="200" header-align="center"></vxe-column>
            <vxe-column field="height" :title="showLang('com.import.cn.pole.height')" sortable width="110" header-align="center"></vxe-column>
            <vxe-column field="armCount" title="杆臂数量" sortable width="110" header-align="center"></vxe-column>
            <vxe-column field="lng" :title="showLang('com.import.cn.pole.lng')" sortable width="150" header-align="center"></vxe-column>
            <vxe-column field="lat" :title="showLang('com.import.cn.pole.lat')" sortable width="150" header-align="center"></vxe-column>
            <vxe-column field="code" :title="showLang('com.device.pole.code')" width="270" header-align="center"></vxe-column>
            <vxe-column field="location" title="安装地址" width="280" header-align="center"></vxe-column>
            <vxe-column field="buildDateName" title="安装日期" width="110" header-align="center"></vxe-column>
          </vxe-table>
        </div>
        <vxe-pager class="list-other" :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
        <!-- <div class="list-other"></div> -->
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalAreaEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      groupTreeData: [],
      loading: false,
      filter: {
        groupId: 0,
        name: "",
        armCount: 0,
        heightMin: 0,
        heightMax: 100,
        latMin: 0,
        latMax: 90,
        lngMin: 0,
        lngMax: 180,
        size: 20,
        index: 1,
      },
      list: [],
      total: 0,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getGroups();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  mounted: function(){
  },
  methods: {
    treeSelected: function(){
      this.search();
    },
    search: function(){
      this.filter.index=1;
      this.getList();
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getList: function () {
      let groupId = 0;
      let nodes = this.$refs.tree.getSelectedNodes();
      if(nodes.length > 0){
        groupId = nodes[0].id;
      }
      this.filter.groupId = groupId; // this.groupTreeSelectedNode.id;
      this.$axios.post(`common/pole/QueryByFilter`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
        }
      });
    },
    getGroups: async function(){
      let res = await this.$axios.post(`sys/auth/QueryGroupTree`, {});
      if(res.code !== 0)return;
      if(res.data.length > 0){
        res.data[0].selected = true;
      }
      this.$set(this, "groupTreeData", res.data);
      this.search();
    },
    ok: async function () {
      let item = this.$refs.chkTable.getRadioRecord();
      // console.log('grid sel', item)
      if(!item){
        this.$Message.warning('请选择灯杆');
        return;
      }
      this.showModal = false;
      this.$emit('selected', {id: item.id, name: item.name});
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.select-area{
  height: 600px;
  display: flex;
}
.tree-area{
  border: solid 1px rgba(129, 124, 124, 0.377);
  width: 300px;
  flex: none;
  overflow-y: auto;
  padding: 0 10px;
  margin-right: 10px;
}
.list-area{
  border: solid 1px rgba(129, 124, 124, 0.377);
  width: 300px;
  flex: auto;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
}
.list-other{
  /* border: solid 1px rgba(129, 124, 124, 0.377); */
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
}
.list-data{
  /* border: solid 1px rgba(129, 124, 124, 0.377); */
  height: 450px;
  flex: auto;
  /* margin: 10px 0; */
}
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>